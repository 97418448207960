<template>
  <div>
    <b-row align-v="center" align-h="between">
      <b-col cols="12" md="6">
        <h1 class="hp-mb-0">Stocks</h1>
      </b-col>
      <b-col class="hp-flex-none w-auto">
        <b-dropdown
          v-model="selected"
          text="Pilih Informasi"
          variant="white"
          class="dropdown"
          @change="handleDropdownChange"
        >
          <b-dropdown-item
            v-for="option in options"
            :key="option.id"
            :value="option.value"
            :to="option.to"
            class="dropdown-item"
          >
            {{ option.label }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <!-- Inventory Stock Card -->
    <div class="d-flex flex-wrap justify-content-center mt-3" style="gap: 0.5rem">
      <b-card v-for="(bloodType, index) in bloodTypes" :key="index">
        <div class="d-flex justify-content-between">
          <h3>{{ bloodType.type }}</h3>
          <div class="amount">
            <strong>
              <span class="number">{{ bloodType.amount }}</span>
            </strong>
            <span class="label"> Kantong</span>
          </div>
        </div>
        <b-table
          class="table-sm tab"
          :items="bloodType.values"
          :fields="fieldsBloodType"
          bordered
        />
      </b-card>
    </div>

    <!-- Inventory Stock Card -->
    <ChartCard />
  </div>
</template>

<script>
import {
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
} from "bootstrap-vue";
import stokAPI from "../../../../../../api/dashboard/stokAPI";
import ChartCard from "./component/ChartCard.vue";

export default {
  name: "DashboardsStocks",
  components: {
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    BTable,
    BCard,
    BCardBody,
    BCardHeader,
    ChartCard,
  },
  data() {
    return {
      selected: "",
      options: [
        {
          value: "analytics",
          label: "Informasi Umum",
          to: "/dashboards/analytics",
        },
        {
          value: "stocks",
          label: "Informasi Stok Darah",
          to: "/dashboards/stocks",
        },
        {
          value: "finances",
          label: "Informasi Keuangan",
          to: "/dashboards/finances",
        },
      ],
      fieldsBloodType: ["WB", "PRC", "LP", "FFP", "TC"],
      bloodTypes: [
        {
          type: "A Pos",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
        {
          type: "B Pos",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
        {
          type: "AB Pos",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
        {
          type: "O Pos",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
        {
          type: "A Neg",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
        {
          type: "B Neg",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
        {
          type: "AB Neg",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
        {
          type: "O Neg",
          amount: 0,
          values: [{ WB: 0, PRC: 0, LP: 0, FFP: 0, TC: 0 }],
        },
      ],
    };
  },
  methods: {
    handleDropdownChange(value) {
      this.selected = value;
    },
    async getStock() {
      try {
        const { data: response } = await stokAPI.getStock();
        response.data.forEach((item) => {
          const bloodType = this.bloodTypes.find(
            (bt) => bt.type === item.blood_type
          );
          if (bloodType) {
            bloodType.amount = item.total;
            Object.keys(item.detail).forEach((key) => {
              bloodType.values[0][key] = item.detail[key];
            });
          }
        });
      } catch (error) {
        console.error("Error fetching stock data:", error);
      }
    },
  },
  mounted() {
    this.getStock();
  },
};
</script>

<style scoped>
.dropdown {
  font-size: 14px;
  background-color: white;
  width: 180px;
}
.dropdown-item {
  font-size: 14px;
  width: 167px;
  padding: 7px;
}
</style>
