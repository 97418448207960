<template>
  <b-card>
    <b-card-header>
      <p class="h2 text-capitalize">{{ title }}</p>
    </b-card-header>
    <div class="overflow-hidden">
      <apexchart
        :type="type"
        height="300"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </b-card>
</template>

<script>
import { defineComponent, computed } from "vue";
import { BCard, BCardHeader } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default defineComponent({
  name: "ChartCard",
  components: { BCard, BCardHeader, apexchart: VueApexCharts },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = computed(() => ({
      ...props.options,
      chart: {
        ...props.options.chart,
        fontFamily: "Manrope, sans-serif",
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "Total",
                formatter: (w) =>
                  w.globals.seriesTotals.reduce((a, b) => a + b, 0),
              },
            },
          },
        },
      },
    }));

    return { chartOptions };
  },
});
</script>
